import React from 'react'
import { getBarChart } from '../helpers/barCharts'
import Parser from "html-react-parser";
import { Chart as ChartJS, CategoryScale, LinearScale, LogarithmicScale, BarElement, Title, Tooltip, Legend } from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getColor, getConvertedValue } from '../helpers/utils';


ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  ChartDataLabels
);



export default function PathogenDescriptions(props) {

  let resultValue = getConvertedValue(props.currentResultValue);

  return (
    <div className="row mb-3 mb-md-0 ml-md-3" style={window.innerWidth > 768 ? {borderBottom: '1px gray solid'} : {}}>
      <section className="col-2 mx-auto my-sm-auto mb-3 mb-md-0 my-md-auto d-flex justify-content-center align-items-center" style={{backgroundColor: getColor(props.currentResultID, props.allResults), fontSize: '1.25rem', fontWeight: 'bold', color: 'white', borderRadius: '50%', maxWidth: '5rem', height: '5rem'}}>
        {resultValue}
      </section>
      <div className="col-10 pl-3 mx-auto">
        <section className="font-weight-bold mb-2 text-sm-left">{props.test.Name} ({props.test.ShortName})</section>
        <p className={"text-sm-left"}style={{fontSize: '0.8rem'}}>{Parser(props.test.ReportDescription)}</p>
      </div>
    </div>
  )
}