import React from "react"

import FilterCard from "../FilterCard";

interface ModalFormState{
    filter?:any
}

interface ModalFormProps{
    title:string
    ID:string
    onSubmit:(any) => void
    onChange?:(any) => void
    fields:any[]
    btnText?
}

class ModalForm extends React.Component<ModalFormProps, ModalFormState>{
    // public filterRef: React.RefObject<FilterCard>;

    constructor(props) {
        super(props);
        this.state = {};
        this.onSubmit = this.onSubmit.bind(this);
        // this.filterRef = React.createRef();
        this.assignClearState = this.assignClearState.bind(this);
    }

    onSubmit(e){
        this.props.onSubmit(this.state.filter);
    }

    clearFilterState:() => void = null;

    assignClearState(func){
        this.clearFilterState = func;
    }

    clearState = () =>{
        this.clearFilterState();
    }

    render() {
        let title = this.props.title;
        return (
            <div className="modal fade form_modal" id={this.props.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        {/*<Modal_Header title={title}/>*/}
                        <div className="modal-header">
                        <h5 className="modal-title" id="result_modal_label">{this.props.title}</h5>
                        <button style={{outline: 'none'}}  type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                        <div className="modal-body ">
                            <FilterCard passClearStateFunc={this.assignClearState}
                                fields={this.props.fields}
                                filterChanged={(filter) => this.setState({filter: filter},() =>this.props.onChange ? this.props.onChange(filter) : null)}
                            />

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit}>{this.props.btnText ? this.props.btnText : "Save changes"}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalForm;