import React, { useState, useEffect } from 'react'
import { BsCheck, BsFilter } from 'react-icons/bs';
import Select from "react-select";
import { sweetalert } from '../../App'
import { SampleFilter } from '../../types/Sample'
import { getBoolOptionsWithNullString, getNullOptions, getUnwrappedSelect, parseDate, prepareMultiSelect } from '../../util/FormatUtil'
import DatePicker from "react-datepicker";
import moment from 'moment';
import PhoneInput from "react-phone-number-input";
import CustomInput from '../form/CustomInput';
import CustomSelect from '../form/CustomSelect';
import CustomDate from '../form/CustomDate';
import CustomPhone from '../form/CustomPhone';
import { customStyles } from '../../util/FormatUtil';
import { keyframes } from 'styled-components';

//get filtered object to return

const SampleFilterTabs = (props, ref) => {

    const [filterObj, setFilterObj] = useState<SampleFilter>({
        PodID: null,
        FacilityID: null,
        FacilityGroup: null,
        PatientFirstName: "",
        PatientMiddleName: "",
        PatientLastName: "",
        PatientDOB: null,
        NullPatientDOB: null,
        PatientEthnicityID: null,
        PatientRaceID: null,
        PatientGenderID: null,
        PatientEmail: "",
        PatientPhone: "",
        NullPatientPhone: null,
        PatientCity: "",
        PatientZip: "",
        PatientState: null,
        PatientCounty: "",
        PatientCountry: null,
        LinkedApptsID: "",
        ReqNum: "",
        BatchNum: "",
        SpecimenID: "",
        InternalSpecimenID: "",
        BinID: "",
        Lot: "",
        BeginCreatedDate: null,
        EndCreatedDate: null,
        NullCreatedDate: null,
        BeginCollectionDate: null,
        EndCollectionDate: null,
        NullCollectionDate: null,
        BeginReceivedDate: null,
        EndReceivedDate: null,
        NullReceivedDate: null,
        BeginResultDate: null,
        EndResultDate: null,
        NullResultDate: null,
        BeginResultsApprovedDate: null,
        EndResultsApprovedDate: null,
        NullResultsApprovedDate: null,
        BeginResultsAccessedDate: null,
        EndResultsAccessedDate: null,
        NullResultsAccessedDate: null,
        BeginReportedDate: null,
        EndReportedDate: null,
        NullReportedDate: null,
        ResultIDs: "",
        SpecimenSourceID: "",
        TestIDs: "",
        RerunFlag: [{ label: "No", value: "*null" }],
        ApptCanceled: [{ label: "No", value: "*null" }],
        ProviderFirstName: "",
        ProviderLastName: "",
        ServiceID: null
    })
    const [isApptFilterActive, setIsApptFilterActive] = useState(false)
    const [isSampleFilterActive, setIsSampleFilterActive] = useState(false)
    const [isFacilityFilterActive, setIsFacilityFilterActive] = useState(false)
    const [isPatientFilterActive, setIsPatientFilterActive] = useState(false)
    const [isApptTabActive, setIsApptTabActive] = useState(true)
    const [isSampleTabActive, setIsSampleTabActive] = useState(false)
    const [isFacilityTabActive, setIsFacilityTabActive] = useState(false)
    const [isPatientTabActive, setIsPatientTabActive] = useState(false)


    const getFilterObj = () => {
        return {
          PodID: filterObj.PodID ? getUnwrappedSelect(filterObj.PodID) : null,
          Facilities: filterObj.FacilityID ? prepareMultiSelect(filterObj.FacilityID) : null,
          FacilityGroup: filterObj.FacilityGroup ? prepareMultiSelect(filterObj.FacilityGroup) : null,
          PatientFirstName: filterObj.PatientFirstName ? filterObj.PatientFirstName : null,
          PatientMiddleName: filterObj.PatientMiddleName ? filterObj.PatientMiddleName : null,
          PatientLastName: filterObj.PatientLastName ? filterObj.PatientLastName : null,
          PatientDOB: filterObj.NullPatientDOB ? null : filterObj.PatientDOB ? parseDate(new Date(filterObj.PatientDOB)) : null,
          NullPatientDOB: filterObj.NullPatientDOB ? filterObj.NullPatientDOB : null,
          PatientEthnicityID: filterObj.PatientEthnicityID ? prepareMultiSelect(filterObj.PatientEthnicityID) : null,
          PatientRaceID: filterObj.PatientRaceID ? prepareMultiSelect(filterObj.PatientRaceID) : null,
          PatientGenderID: filterObj.PatientGenderID ? prepareMultiSelect(filterObj.PatientGenderID) : null,
          PatientEmail: filterObj.PatientEmail ? filterObj.PatientEmail : null,    
          PatientPhone: filterObj.PatientPhone ? filterObj.PatientPhone : null,
          NullPatientPhone: filterObj.NullPatientPhone ? filterObj.NullPatientPhone : null,
          PatientCity: filterObj.PatientCity ? filterObj.PatientCity : null,
          PatientZip: filterObj.PatientZip ? filterObj.PatientZip : null,
          PatientState: filterObj.PatientState ? prepareMultiSelect(filterObj.PatientState) : null,
          PatientCounty: filterObj.PatientCounty ? filterObj.PatientCounty : null,
          PatientCountry: filterObj.PatientCountry ? prepareMultiSelect(filterObj.PatientCountry) : null,
          LinkedApptsID: filterObj.LinkedApptsID ? filterObj.LinkedApptsID : null,
          ReqNum: filterObj.ReqNum ? filterObj.ReqNum : null,
          BatchNum: filterObj.BatchNum ? prepareMultiSelect(filterObj.BatchNum) : null,
          SpecimenID: filterObj.SpecimenID ? filterObj.SpecimenID : null,
          InternalSpecimenID: filterObj.InternalSpecimenID ? filterObj.InternalSpecimenID : null,
          BinID: filterObj.BinID ? prepareMultiSelect(filterObj.BinID) : null,
          Lot: filterObj.Lot ? filterObj.Lot : null,    
          BeginCreatedDate: filterObj.NullCreatedDate ? null : filterObj.BeginCreatedDate ? filterObj.BeginCreatedDate : null,
          EndCreatedDate: filterObj.NullCreatedDate ? null : filterObj.EndCreatedDate ? filterObj.EndCreatedDate : null,
          NullCreatedDate: filterObj.NullCreatedDate ? filterObj.NullCreatedDate : null,    
          BeginCollectionDate: filterObj.NullCollectionDate ? null : filterObj.BeginCollectionDate ? filterObj.BeginCollectionDate : null,
          EndCollectionDate: filterObj.NullCollectionDate ? null : filterObj.EndCollectionDate ? filterObj.EndCollectionDate : null,
          NullCollectionDate: filterObj.NullCollectionDate ? filterObj.NullCollectionDate : null,    
          BeginReceivedDate: filterObj.NullReceivedDate ? null : filterObj.BeginReceivedDate ? filterObj.BeginReceivedDate : null,
          EndReceivedDate: filterObj.NullReceivedDate ? null :filterObj.EndReceivedDate ? filterObj.EndReceivedDate : null,
          NullReceivedDate: filterObj.NullReceivedDate ? filterObj.NullReceivedDate : null,    
          BeginResultDate: filterObj.NullResultDate ? null : filterObj.BeginResultDate ? filterObj.BeginResultDate : null,
          EndResultDate: filterObj.NullResultDate ? null : filterObj.EndResultDate ? filterObj.EndResultDate : null,          
          NullResultDate: filterObj.NullResultDate ? filterObj.NullResultDate : null,    
          BeginResultsApprovedDate: filterObj.NullResultsApprovedDate ? null : filterObj.BeginResultsApprovedDate ? filterObj.BeginResultsApprovedDate : null,
          EndResultsApprovedDate: filterObj.NullResultsApprovedDate ? null : filterObj.EndResultsApprovedDate ? filterObj.EndResultsApprovedDate : null,
          NullResultsApprovedDate: filterObj.NullResultsApprovedDate ? filterObj.NullResultsApprovedDate : null,
          BeginResultsAccessedDate: filterObj.NullResultsAccessedDate ? null : filterObj.BeginResultsAccessedDate ? filterObj.BeginResultsAccessedDate : null,
          EndResultsAccessedDate: filterObj.NullResultsAccessedDate ? null : filterObj.EndResultsAccessedDate ? filterObj.EndResultsAccessedDate : null,
          NullResultsAccessedDate: filterObj.NullResultsAccessedDate ? filterObj.NullResultsAccessedDate : null,    
          BeginReportedDate: filterObj.NullReportedDate ? null : filterObj.BeginReportedDate ? filterObj.BeginReportedDate : null,
          EndReportedDate: filterObj.NullReportedDate ? null : filterObj.EndReportedDate ? filterObj.EndReportedDate: null,
          NullReportedDate: filterObj.NullReportedDate ? filterObj.NullReportedDate : null,    
          ResultIDs: filterObj.ResultIDs ? prepareMultiSelect(filterObj.ResultIDs) : null,
          SpecimenSourceID: filterObj.SpecimenSourceID ? prepareMultiSelect(filterObj.SpecimenSourceID) : null,
          TestIDs: filterObj.TestIDs ? prepareMultiSelect(filterObj.TestIDs) : null,
          ServiceID: filterObj.ServiceID ? prepareMultiSelect(filterObj.ServiceID) : null,    
          //passing empty array if greater than 1 because if both 1 and null are selected then that is all the records.  same for ApptCanceled
          RerunFlag: filterObj.RerunFlag ? filterObj.RerunFlag.length > 1 ? [] : prepareMultiSelect(filterObj.RerunFlag): null,
          ApptCanceled: filterObj.ApptCanceled ? filterObj.ApptCanceled.length > 1 ? [] : prepareMultiSelect(filterObj.ApptCanceled) : null,
          ProviderFirstName: filterObj.ProviderFirstName ? filterObj.ProviderFirstName : null,
          ProviderLastName: filterObj.ProviderLastName ? filterObj.ProviderLastName : null,
        };
    }

    const dateCheck = (beginDateState, endDateState, beginLabel, endLabel, label) => {
        let valid = true
        if ((beginDateState && !endDateState) || (!beginDateState && endDateState)) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: `Please enter a valid ${beginLabel} Date and an ${endLabel} Date`,
          });
          valid = false;
        }
    
        if (beginDateState > endDateState) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: `${beginLabel} Date cannot be after ${endLabel} Date`,
          });
          valid = false;
        }
    
        if (beginDateState > new Date() || endDateState > new Date()) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: `Cannot search for ${label} Date in the future`,
          });
          valid = false;
        }

        if (beginDateState > new Date() || endDateState > new Date()) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: `Cannot search for ${label} Date in the future`,
          });
          valid = false;
        }
        return valid
    }

    const handleChange = (e, stateToSet, type='input', nullStateToSet=null) => {
        if(type === 'input'){
            setFilterObj((prevState) => ({
                ...prevState,
                [stateToSet]: e.target.value 
            }))
        }
        else if(type === 'select'){
            setFilterObj((prevState) => ({
                ...prevState,
                [stateToSet]: e 
            }))
        }
        else if(type === 'date' || type==='phone'){
            setFilterObj((prevState) => ({
                ...prevState,
                [stateToSet]: null,
                [nullStateToSet]: e,
            }))
        }

        handleFilteredTab()
    }
    
    const handleDateOnChange = (value, nullState, state) => {
        if (value) {
            setFilterObj((prevState) => ({
                ...prevState,
                [nullState]: null,
                [state]: value
            }))
        } else {
            setFilterObj((prevState) => ({
                ...prevState,
                [nullState]: null,
                [state]: null
            }))
        }
    }

    //leaving here because this only occurs in the Sample Filter
    const getDateRange = (beginStateToSet, beginState, endStateToSet, endState, nullStateToSet,nullState, label, tooltip, options) => {
        return (
            <div className="form-group row">
                <div className={"col-10 col-md-8 pl-0"} data-toggle={"tooltip"} data-placement={"top"} title={tooltip} >
                    <label htmlFor={label} style={{ fontSize: "0.8em" }} className={"pt-1 font-weight-bold"} > {label} </label>
                </div>
                <div className="col-2">
                    {" "}
                    {beginState || endState || nullState ? (
                    <BsCheck className={"float-right"} color={"#21212E"} size={20} />
                    ) : null}
                </div>
                <div className={"col-12 col-md-10 pl-0 pr-1"}>
                    <div className="row">
                        <div className={"col-4"}>
                            <label id={`begin${label.replaceAll(' ', '')}Filter`} aria-label='' htmlFor='beginDate'>Begin: </label>
                        </div>
                        <div className={"col-8 text-lg-right"} id={label} >
                            <DatePicker ariaLabelledBy={`begin${label.replaceAll(' ', '')}Filter`} name={"beginDate"} minDate={new Date("01-01-1000")} maxDate={new Date("12-31-9999")} placeholderText="--/--/----" selected={ beginState ? Date.parse(moment(beginState, "MM-DD-YYYY").toISOString()) : null } onChange={(e) => handleDateOnChange(e, nullStateToSet, beginStateToSet) } />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            <label id={`end${label.replaceAll(' ', '')}Filter`} htmlFor='endDate'>End: </label>
                        </div>
                        <div className={"col-8 text-lg-right"} id={label}>
                            <DatePicker ariaLabelledBy={`end${label.replaceAll(' ', '')}Filter`} name={"endDate"} minDate={new Date("01-01-1000")} maxDate={new Date("12-31-9999")} placeholderText="--/--/----" selected={ endStateToSet ? Date.parse(moment(endState, "MM-DD-YYYY").toISOString()) : null } onChange={(e) => handleDateOnChange(e, nullStateToSet, endStateToSet) } />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={label === 'Collection Date' ? "col-12" : "col-12 pl-md-1 pr-md-3 ml-md-2 pr-lg-4"}>
                            <div>
                                <Select styles={customStyles} isMulti={true} isSearchable={true} aria-label={label} placeholder={<div className="accessibilityText">Please Select...</div>} onChange={(e) =>{ setFilterObj((prevState) => ({ ...prevState, [beginStateToSet]: null, [endStateToSet]: null, [nullStateToSet]: e, })) }} className={"state_select"} options={options} value={nullState} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const handleActiveTab = (tab) => {
        switch (tab) {
            case 'appt':
                setIsApptTabActive(true)
                setIsSampleTabActive(false)
                setIsFacilityTabActive(false)
                setIsPatientTabActive(false)
                break;
            case 'sample':
                setIsApptTabActive(false)
                setIsSampleTabActive(true)
                setIsFacilityTabActive(false)
                setIsPatientTabActive(false)
                break;
            case 'facility':
                setIsApptTabActive(false)
                setIsSampleTabActive(false)
                setIsFacilityTabActive(true)
                setIsPatientTabActive(false)
                break;
            case 'patient':
                setIsApptTabActive(false)
                setIsSampleTabActive(false)
                setIsFacilityTabActive(false)
                setIsPatientTabActive(true)
            break;
            default:
                break;
        }
    }

    const handleFilteredTab = () => {
        //Appointment tab
        if(!filterObj.ReqNum && !filterObj.LinkedApptsID && !filterObj.ServiceID && !filterObj.ApptCanceled && !filterObj.ProviderFirstName && !filterObj.ProviderLastName){
            setIsApptFilterActive(false)
        }
        else{
            setIsApptFilterActive(true)
        }

        //Sample tab
        if(!filterObj.SpecimenID && !filterObj.InternalSpecimenID && !filterObj.BatchNum && !filterObj.BinID && ! filterObj.SpecimenSourceID && !filterObj.TestIDs && !filterObj.ResultIDs && !filterObj.RerunFlag && !filterObj.Lot && !filterObj.BeginCollectionDate && !filterObj.EndCollectionDate && !filterObj.NullCollectionDate && !filterObj.BeginReceivedDate && !filterObj.EndReceivedDate && !filterObj.NullReceivedDate && !filterObj.BeginResultDate && !filterObj.EndResultDate && !filterObj.NullResultDate && !filterObj.BeginResultsApprovedDate && !filterObj.EndResultsApprovedDate && !filterObj.NullResultsApprovedDate && !filterObj.BeginResultsAccessedDate && !filterObj.EndResultsAccessedDate && !filterObj.NullResultsAccessedDate && !filterObj.BeginCreatedDate && !filterObj.EndCreatedDate && !filterObj.NullCreatedDate && !filterObj.BeginReportedDate && !filterObj.EndReportedDate && !filterObj.NullReportedDate){
            setIsSampleFilterActive(false)
        }
        else{
            setIsSampleFilterActive(true)
        }

        //Facility tab
        if(!filterObj.PodID && !filterObj.FacilityGroup && !filterObj.FacilityID){
            setIsFacilityFilterActive(false)
        }
        else{
            setIsFacilityFilterActive(true)
        }

        //Patient tab
        if(!filterObj.PatientFirstName && !filterObj.PatientMiddleName && !filterObj.PatientLastName && !filterObj.PatientEmail && !filterObj.PatientDOB && !filterObj.PatientEthnicityID && !filterObj.PatientRaceID && !filterObj.PatientGenderID && !filterObj.PatientPhone && !filterObj.PatientCity && !filterObj.PatientZip && !filterObj.PatientState && !filterObj.PatientCounty && !filterObj.PatientCountry){
            setIsPatientFilterActive(false)
        }
        else{
            setIsPatientFilterActive(true)
        }
    }

    React.useImperativeHandle(ref, () => ({
        preSubmitChecks(){
            let filteredObj = getFilterObj()
            let keys = Object.keys(filteredObj)
            let hasValue = keys.find((k) => filteredObj[k])
            let dateIsValid = true;

            if(!hasValue){
                sweetalert.fire({
                    icon: "error",
                    title: "",
                    text: "Please choose a value to search",
                });
                return null
            }
            if (filterObj.BeginCreatedDate || filterObj.EndCreatedDate) {
                dateIsValid = dateCheck(filterObj.BeginCreatedDate, filterObj.EndCreatedDate, "Beginning Created", "Ending Created", "Created");
            }
            if (filterObj.BeginCollectionDate || filterObj.EndCollectionDate) {
                dateIsValid = dateCheck(filterObj.BeginCollectionDate, filterObj.EndCollectionDate, "Beginning Collection", "Ending Collection",  "Collection");
            }
            if (filterObj.BeginReceivedDate || filterObj.EndReceivedDate) {
                dateIsValid = dateCheck(filterObj.BeginReceivedDate, filterObj.EndReceivedDate, "Beginning Received", "Ending Received", "Received");
            }
            if (filterObj.BeginResultDate || filterObj.EndResultDate) {
                dateIsValid = dateCheck(filterObj.BeginResultDate, filterObj.EndResultDate, "Beginning Result", "Ending Result", "Result");
            }
            if (filterObj.BeginResultsApprovedDate || filterObj.EndResultsApprovedDate) {
                dateIsValid = dateCheck(filterObj.BeginResultsApprovedDate, filterObj.EndResultsApprovedDate, "Beginning Results Approved", "Ending Results Approved", "Results Approved");
            }
            if (filterObj.BeginResultsAccessedDate || filterObj.EndResultsAccessedDate) {
                dateIsValid = dateCheck(filterObj.BeginResultsAccessedDate, filterObj.EndResultsAccessedDate, "Beginning Results Accessed", "Ending Results Accessed", "Results Accessed");
            }
            if (filterObj.BeginReportedDate || filterObj.EndReportedDate) {
                dateIsValid = dateCheck(filterObj.BeginReportedDate, filterObj.EndReportedDate, "Beginning Reported", "Ending Reported", "Reported");
            }

            if (dateIsValid) {
                return filteredObj
            }
            else{
                return null
            }
        },

        clear(){
            setFilterObj({
                PodID: null,
                FacilityID: null,
                FacilityGroup: null,
                PatientFirstName: "",
                PatientMiddleName: "",
                PatientLastName: "",
                PatientDOB: null,
                NullPatientDOB: null,
                PatientEthnicityID: null,
                PatientRaceID: null,
                PatientGenderID: null,
                PatientEmail: "",
                PatientPhone: "",
                NullPatientPhone: null,
                PatientCity: "",
                PatientZip: "",
                PatientState: null,
                PatientCounty: "",
                PatientCountry: null,
                LinkedApptsID: "",
                ReqNum: "",
                BatchNum: "",
                SpecimenID: "",
                InternalSpecimenID: "",
                BinID: "",
                Lot: "",
                BeginCreatedDate: null,
                EndCreatedDate: null,
                NullCreatedDate: null,
                BeginCollectionDate: null,
                EndCollectionDate: null,
                NullCollectionDate: null,
                BeginReceivedDate: null,
                EndReceivedDate: null,
                NullReceivedDate: null,
                BeginResultDate: null,
                EndResultDate: null,
                NullResultDate: null,
                BeginResultsApprovedDate: null,
                EndResultsApprovedDate: null,
                NullResultsApprovedDate: null,
                BeginResultsAccessedDate: null,
                EndResultsAccessedDate: null,
                NullResultsAccessedDate: null,
                BeginReportedDate: null,
                EndReportedDate: null,
                NullReportedDate: null,
                ResultIDs: "",
                SpecimenSourceID: "",
                TestIDs: "",
                RerunFlag: "",
                ApptCanceled: "",
                ProviderFirstName: "",
                ProviderLastName: "",
                ServiceID: null
            })
        }
    }))



    useEffect(() => {
        handleFilteredTab()
    }, [filterObj])
    

    return (
        <div className="row">
            <div className="col-12">
                <nav className='d-md-none'>
                    <div className="nav nav-pills nav-fill mb-2" id="nav-tab" role="tablist" style={{borderBottom: '0.1em solid lightgrey'}}>
                        <a className="nav-item nav-link active col-6 col-md-3" style={{borderRight: '0.1em solid lightgrey', borderBottom: '0.1em solid lightgrey'}}  id="nav-appointment-information-tab" data-toggle="tab" href="#nav-appointment-information" role="button" aria-controls="nav-appointment-information" aria-selected="true" onClick={() => handleActiveTab('appt')}>
                            Appointment
                            <BsFilter className={isApptFilterActive ? "float-right" : "invisible"} color={isApptTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" style={{borderBottom: '0.1em solid lightgrey'}} id="nav-sample-information-tab" data-toggle="tab" href="#nav-sample-information" role="button" aria-controls="nav-sample-information" aria-selected="true" onClick={() => handleActiveTab('sample')}>
                            Sample
                            <BsFilter className={isSampleFilterActive ? "float-right" : "invisible"} color={isSampleTabActive ? "aliceblue" :"#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" style={{borderRight: '0.1em solid lightgrey'}} id="nav-facility-information-tab" data-toggle="tab" href="#nav-facility-information" role="button" aria-controls="nav-facility-information" aria-selected="false" onClick={() => handleActiveTab('facility')}>
                            Facility
                            <BsFilter className={isFacilityFilterActive ? "float-right" : "invisible"} color={isFacilityTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" id="nav-patient-information-tab" data-toggle="tab" href="#nav-patient-information" role="button" aria-controls="nav-patient-information" aria-selected="false" onClick={() => handleActiveTab('patient')}>
                            Patient
                            <BsFilter className={isPatientFilterActive ? "float-right" : "invisible"} color={isPatientTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                    </div>
                </nav>
                <nav className='d-none d-md-block'>
                    <div className="nav nav-pills nav-fill mb-2" id="nav-tab" role="tablist" style={{borderBottom: '0.1em solid lightgrey'}}>
                        <a className="nav-item nav-link active col-6 col-md-3" style={{borderRight: '0.1em solid lightgrey'}} id="nav-appointment-information-tab" data-toggle="tab" href="#nav-appointment-information" role="button" aria-controls="nav-appointment-information" aria-selected="true" onClick={() => handleActiveTab('appt')}>
                            Appointment
                            <BsFilter className={isApptFilterActive ? "float-right" : "invisible"} color={isApptTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" style={{borderRight: '0.1em solid lightgrey'}}  id="nav-sample-information-tab" data-toggle="tab" href="#nav-sample-information" role="button" aria-controls="nav-sample-information" aria-selected="true" onClick={() => handleActiveTab('sample')}>
                            Sample
                            <BsFilter className={isSampleFilterActive ? "float-right" : "invisible"} color={isSampleTabActive ? "aliceblue" :"#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" style={{borderRight: '0.1em solid lightgrey'}}  id="nav-facility-information-tab" data-toggle="tab" href="#nav-facility-information" role="button" aria-controls="nav-facility-information" aria-selected="false" onClick={() => handleActiveTab('facility')}>
                            Facility
                            <BsFilter className={isFacilityFilterActive ? "float-right" : "invisible"} color={isFacilityTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                        <a className="nav-item nav-link col-6 col-md-3" id="nav-patient-information-tab" data-toggle="tab" href="#nav-patient-information" role="button" aria-controls="nav-patient-information" aria-selected="false" onClick={() => handleActiveTab('patient')}>
                            Patient
                            <BsFilter className={isPatientFilterActive ? "float-right" : "invisible"} color={isPatientTabActive ? "aliceblue" : "#21212E"} size={20} />
                        </a>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-appointment-information" role="tabpanel" aria-labelledby="nav-appointment-information-tab">
                    {/* appointment information card  */}
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 px-5 pl-md-5 pr-md-0">
                            <CustomInput fromFilterPage={true}  value={filterObj.ReqNum} label={"Confirmation Number"} aria-label={"Confirmation #"} tooltip={"A patient's unique code tied to their sample"} handleChange={(e) => handleChange(e, "ReqNum")} />
                            <CustomInput fromFilterPage={true}  value={filterObj.LinkedApptsID} label={"Appointment Number"} tooltip={"A patient's unique code tied to their appointment"} handleChange={(e) => handleChange(e, "LinkedApptsID")} />
                            <span className='d-lg-none'>
                                <CustomSelect fromFilterPage={true} value={filterObj.ServiceID} label={"Service"} tooltip={"Selected Service"} handleChange={(e) => handleChange(e, "ServiceID", 'select')} options={props.services}/>
                            </span>
                        </div>
                        <div className='d-none d-md-block col-md-1 col-lg-4'>
                            <span className='d-md-none d-lg-block'>
                                <CustomSelect fromFilterPage={true} value={filterObj.ServiceID} label={"Service"} tooltip={"Selected Service"} handleChange={(e) => handleChange(e, "ServiceID", 'select')} options={props.services}/>
                                <CustomSelect fromFilterPage={true} value={filterObj.ApptCanceled} label={"Appointment Canceled"} tooltip={`Appointment status. "Yes" returns canceled appointments, "No" returns active appointments.`} handleChange={(e) => handleChange(e, "ApptCanceled", 'select')} options={getBoolOptionsWithNullString()}/>
                            </span>
                        </div>
                        <div className="col-12 col-md-5 col-lg-4 px-5 pl-md-0 pr-md-0">
                            <span className='d-lg-none'>
                                <CustomSelect fromFilterPage={true} value={filterObj.ApptCanceled} label={"Appointment Canceled"} tooltip={`Appointment status. "Yes" returns canceled appointments, "No" returns active appointments.`} handleChange={(e) => handleChange(e, "ApptCanceled", 'select')} options={getBoolOptionsWithNullString()}/>
                            </span>
                            <CustomInput fromFilterPage={true}  value={filterObj.ProviderFirstName} label={"Provider First Name"} tooltip={"The first name of the Provider that is assigned to a sample"} handleChange={(e) => handleChange(e, "ProviderFirstName")} />
                            <CustomInput fromFilterPage={true}  value={filterObj.ProviderLastName} label={"Provider Last Name"} tooltip={"The last name of the Provider that is assigned to a sample"} handleChange={(e) => handleChange(e, "ProviderLastName")} />
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-sample-information" role="tabpanel" aria-labelledby="nav-sample-information-tab">
                    {/* sample information card  */}
                    <div className="row">
                        <div className="col-12 col-md-6 col-xxl-4 px-5 pl-md-5 pr-md-0">
                            <CustomInput fromFilterPage={true} value={filterObj.SpecimenID} label={"Specimen ID"} tooltip={"A unique identifier assigned to a sample"} handleChange={(e) => handleChange(e, "SpecimenID")} />
                            <CustomInput fromFilterPage={true} value={filterObj.InternalSpecimenID} label={"Internal Specimen ID"} tooltip={"A unique secondary identifier assigned to a sample"} handleChange={(e) => handleChange(e, "InternalSpecimenID")} />
                            <CustomSelect fromFilterPage={true} value={filterObj.BatchNum} label={"Batch Number"} tooltip={`Assigned when processing a batch of test samples`} handleChange={(e) => handleChange(e, "BatchNum", 'select')} options={props.batchesForFilter}/>
                            <CustomSelect fromFilterPage={true} value={filterObj.BinID} label={"Bin ID"} tooltip={`A unique identifier that ties a sample to receiving at the lab`} handleChange={(e) => handleChange(e, "BinID", 'select')} options={props.binIDsForFilter}/>
                            <CustomSelect fromFilterPage={true} value={filterObj.SpecimenSourceID} label={"Specimen Source"} tooltip={`The type of sample that was collected`} handleChange={(e) => handleChange(e, "SpecimenSourceID", 'select')} options={props.specimenSources}/>
                            <CustomSelect fromFilterPage={true} value={filterObj.TestIDs} label={"Test Type"} tooltip={`The testing method`} handleChange={(e) => handleChange(e, "TestIDs", 'select')} options={props.tests}/>
                            <CustomSelect fromFilterPage={true} value={filterObj.ResultIDs} label={"Result"} tooltip={`The result assigned to a sample`} handleChange={(e) => handleChange(e, "ResultIDs", 'select')} options={props.resultTypes}/>
                            <span className='d-xxl-none'>
                                <CustomSelect fromFilterPage={true} value={filterObj.RerunFlag} label={"Rerun Flag"} tooltip={`Samples marked for rerun. "Yes" returns samples marked for rerun.`} handleChange={(e) => handleChange(e, "RerunFlag", 'select')} options={getBoolOptionsWithNullString()}/>
                                <CustomInput fromFilterPage={true} value={filterObj.Lot} label={"Lot"} tooltip={"Test lot number (e.g., rapid test lot number.)"} handleChange={(e) => handleChange(e, "Lot")} />
                                {getDateRange("BeginCollectionDate", filterObj.BeginCollectionDate, "EndCollectionDate", filterObj.EndCollectionDate, "NullCollectionDate", filterObj.NullCollectionDate, "Collection Date", "Date a test sample was collected.", getNullOptions())}
                            </span>
                        </div>
                        <div className='d-none d-md-block col-md-1 col-xxl-4'>
                            <span className='d-md-none d-xxl-block'>
                                <CustomSelect fromFilterPage={true} value={filterObj.RerunFlag} label={"Rerun Flag"} tooltip={`Samples marked for rerun. "Yes" returns samples marked for rerun.`} handleChange={(e) => handleChange(e, "RerunFlag", 'select')} options={getBoolOptionsWithNullString()}/> 
                                <CustomInput fromFilterPage={true} value={filterObj.Lot} label={"Lot"} tooltip={"Test lot number (e.g., rapid test lot number.)"} handleChange={(e) => handleChange(e, "Lot")} />
                                {getDateRange("BeginCollectionDate", filterObj.BeginCollectionDate, "EndCollectionDate", filterObj.EndCollectionDate, "NullCollectionDate", filterObj.NullCollectionDate, "Collection Date", "Date a test sample was collected.", getNullOptions())}
                                {getDateRange("BeginReceivedDate", filterObj.BeginReceivedDate, "EndReceivedDate", filterObj.EndReceivedDate, "NullReceivedDate", filterObj.NullReceivedDate, "Received Date", "The date a sample was received at the lab.", getNullOptions())}
                                {getDateRange("BeginResultDate", filterObj.BeginResultDate, "EndResultDate", filterObj.EndResultDate, "NullResultDate", filterObj.NullResultDate, "Result Date", "Date a result was assigned to a test sample (note: results are not sent to the patient until they're approved.", getNullOptions())}
                            </span>
                        </div>
                        <div className="col-12 col-md-5 col-xxl-4 px-5 pl-md-0 pr-md-0">
                            <span className='d-xxl-none'>
                                {getDateRange("BeginReceivedDate", filterObj.BeginReceivedDate, "EndReceivedDate", filterObj.EndReceivedDate, "NullReceivedDate", filterObj.NullReceivedDate, "Received Date", "The date a sample was received at the lab.", getNullOptions())}
                                {getDateRange("BeginResultDate", filterObj.BeginResultDate, "EndResultDate", filterObj.EndResultDate, "NullResultDate", filterObj.NullResultDate, "Result Date", "Date a result was assigned to a test sample (note: results are not sent to the patient until they're approved.", getNullOptions())}
                            </span>
                            {getDateRange("BeginResultsApprovedDate", filterObj.BeginResultsApprovedDate, "EndResultsApprovedDate", filterObj.EndResultsApprovedDate, "NullResultsApprovedDate", filterObj.NullResultsApprovedDate, "Approved Date", "The date a result is approved and sent to the patient.", getNullOptions())}
                            {getDateRange("BeginResultsAccessedDate", filterObj.BeginResultsAccessedDate, "EndResultsAccessedDate", filterObj.EndResultsAccessedDate, "NullResultsAccessedDate", filterObj.NullResultsAccessedDate, "Accessed Date", "The date a patient first accessed their test results.", getNullOptions())}
                            {getDateRange("BeginCreatedDate", filterObj.BeginCreatedDate, "EndCreatedDate", filterObj.EndCreatedDate, "NullCreatedDate", filterObj.NullCreatedDate, "Created Date", "The date that a patient record was created using the Appointment or Submission form.", getNullOptions())}
                            {getDateRange("BeginReportedDate", filterObj.BeginReportedDate, "EndReportedDate", filterObj.EndReportedDate, "NullReportedDate", filterObj.NullReportedDate, "Reported Date", "The date that the sample has been reported to SpringML.", getNullOptions())}
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-facility-information" role="tabpanel" aria-labelledby="nav-facility-information-tab">
                    {/* facility information card  */}
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 px-5 pl-md-5 pr-md-0">
                            <CustomSelect fromFilterPage={true} value={filterObj.PodID} label={"Schedule"} tooltip={`Lists the Schedule ID, Facility Name, and Schedule Date`} handleChange={(e) => handleChange(e, "PodID", 'select')} options={props.pods} disabled={filterObj.FacilityGroup || filterObj.FacilityID}/> 
                            <span className='d-lg-none'>  
                                <CustomSelect fromFilterPage={true} value={filterObj.FacilityGroup} label={"Facility Group"} tooltip={`Facility Group", "Group facility is assigned to`} handleChange={(e) => handleChange(e, "FacilityGroup", 'select')} options={props.facilityGroups} disabled={filterObj.FacilityID || filterObj.PodID}/> 
                            </span>
                        </div>
                        <div className='d-none d-md-block col-md-1 col-lg-4'>
                            <span className='d-md-none d-lg-block'>
                                <CustomSelect fromFilterPage={true} value={filterObj.FacilityGroup} label={"Facility Group"} tooltip={`Facility Group", "Group facility is assigned to`} handleChange={(e) => handleChange(e, "FacilityGroup", 'select')} options={props.facilityGroups} disabled={filterObj.FacilityID || filterObj.PodID}/> 
                            </span>
                        </div>
                        <div className="col-12 col-md-5 col-lg-4 px-5 pl-md-0 pr-md-0">
                            <CustomSelect fromFilterPage={true} value={filterObj.FacilityID} label={"Facility"} tooltip={`Testing locations by name`} handleChange={(e) => handleChange(e, "FacilityID", 'select')} options={props.facilities} disabled={filterObj.FacilityGroup || filterObj.PodID}/> 
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-patient-information" role="tabpanel" aria-labelledby="nav-patient-information-tab">
                    {/* patient information card  */}
                    <div className="row">
                        <div className="col-12 col-md-6 col-xxl-4 px-5 pl-md-5 pr-md-0">
                            <CustomInput fromFilterPage={true} value={filterObj.PatientFirstName} label={"First Name"} tooltip={"The first name of the Patient"} handleChange={(e) => handleChange(e, "PatientFirstName")} />
                            <CustomInput fromFilterPage={true} value={filterObj.PatientMiddleName} label={"Middle Name"} tooltip={"The middle name of the Patient"} handleChange={(e) => handleChange(e, "PatientMiddleName")} />
                            <CustomInput fromFilterPage={true} value={filterObj.PatientLastName} label={"Last Name"} tooltip={"The last name of the Patient"} handleChange={(e) => handleChange(e, "PatientLastName")} />
                            <CustomInput fromFilterPage={true} value={filterObj.PatientEmail} label={"Email"} tooltip={"The email of the Patient"} handleChange={(e) => handleChange(e, "PatientEmail")} />
                            <span className='d-none d-xxl-block'>
                                <CustomDate fromFilterPage={true} value={filterObj.PatientDOB} nullValue={filterObj.NullPatientDOB} label={"Date of Birth"} tooltip={`The date of birth of the Patient`} handleDateOnChange={(e) => handleDateOnChange(e, "NullPatientDOB",  "PatientDOB")} handleChange={(e) => handleChange(e, "PatientDOB", "date", "NullPatientDOB")} options={getNullOptions()}/>
                            </span>
                            <span className='d-xxl-none'>
                                <CustomSelect fromFilterPage={true} value={filterObj.PatientEthnicityID} label={"Ethnicity"} tooltip={`The ethnicity of the Patient`} handleChange={(e) => handleChange(e, "PatientEthnicityID", 'select')} options={props.ethnicity} /> 
                                <CustomSelect fromFilterPage={true} value={filterObj.PatientRaceID} label={"Race"} tooltip={`The race of the Patient`} handleChange={(e) => handleChange(e, "PatientRaceID", 'select')} options={props.race} /> 
                                <CustomSelect fromFilterPage={true} value={filterObj.PatientGenderID} label={"Gender"} tooltip={`The gender of the Patient`} handleChange={(e) => handleChange(e, "PatientGenderID", 'select')} options={props.gender} /> 
                            </span>
                        </div>
                        <div className='d-none d-md-block col-md-1 col-xxl-4'>
                            <span className='d-md-none d-xxl-block'>
                                <CustomSelect fromFilterPage={true} value={filterObj.PatientEthnicityID} label={"Ethnicity"} tooltip={`The ethnicity of the Patient`} handleChange={(e) => handleChange(e, "PatientEthnicityID", 'select')} options={props.ethnicity} /> 
                                <CustomSelect fromFilterPage={true} value={filterObj.PatientRaceID} label={"Race"} tooltip={`The race of the Patient`} handleChange={(e) => handleChange(e, "PatientRaceID", 'select')} options={props.race} /> 
                                <CustomSelect fromFilterPage={true} value={filterObj.PatientGenderID} label={"Gender"} tooltip={`The gender of the Patient`} handleChange={(e) => handleChange(e, "PatientGenderID", 'select')} options={props.gender} /> 
                                <CustomPhone fromFilterPage={true} value={filterObj.PatientPhone} nullValue={filterObj.NullPatientPhone} label={"Phone"} tooltip={`The phone number of the Patient`} handleDateOnChange={(e) => handleDateOnChange(e, "NullPatientPhone",  "PatientPhone")} handleChange={(e) => handleChange(e, "PatientPhone", "phone", "NullPatientPhone")} options={getNullOptions()}/>
                            </span>
                        </div>
                        <div className="col-12 col-md-5 col-xxl-4 px-5 pl-md-0 pr-md-0">
                            <span className='d-xxl-none'>
                                <CustomDate fromFilterPage={true} value={filterObj.PatientDOB} nullValue={filterObj.NullPatientDOB} label={"DOB"} tooltip={`The date of birth of the Patient`} handleDateOnChange={(e) => handleDateOnChange(e, "NullPatientDOB",  "PatientDOB")} handleChange={(e) => handleChange(e, "PatientDOB", "date", "NullPatientDOB")} options={getNullOptions()}/>
                                <CustomPhone fromFilterPage={true} value={filterObj.PatientPhone} nullValue={filterObj.NullPatientPhone} label={"Phone"} tooltip={`The phone number of the Patient`} handleDateOnChange={(e) => handleDateOnChange(e, "NullPatientPhone",  "PatientPhone")} handleChange={(e) => handleChange(e, "PatientPhone", "phone", "NullPatientPhone")} options={getNullOptions()}/>
                            </span>
                            <CustomInput fromFilterPage={true} value={filterObj.PatientCity} label={"City"} tooltip={"The city of the Patient's address"} handleChange={(e) => handleChange(e, "PatientCity")} />
                            <CustomInput fromFilterPage={true} value={filterObj.PatientZip} label={"Zip"} tooltip={"The zip of the Patient's address"} handleChange={(e) => handleChange(e, "PatientZip")} />
                            <CustomSelect fromFilterPage={true} value={filterObj.PatientState} label={"State"} tooltip={`The state of the Patient's address`} handleChange={(e) => handleChange(e, "PatientState", 'select')} options={props.states} /> 
                            <CustomInput fromFilterPage={true} value={filterObj.PatientCounty} label={"County"} tooltip={"The county of the Patient's address"} handleChange={(e) => handleChange(e, "PatientCounty")} />
                            <CustomSelect fromFilterPage={true} value={filterObj.PatientCountry} label={"Country"} tooltip={`The country of the Patient's address`} handleChange={(e) => handleChange(e, "PatientCountry", 'select')} options={props.countries} /> 
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default React.forwardRef(SampleFilterTabs)