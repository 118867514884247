import React from "react";
import {
    hideModal} from "../../util/FormatUtil";
import Parser from "html-react-parser";


interface SampleTestReportState{
    showInBetween:boolean
}
interface SampleTestReportProps{
    selectedTest
    handleShowInBetween?
}
export class SampleTestReportModal extends React.Component<SampleTestReportProps, SampleTestReportState>{
    public static ID = "SampleTestReport";
    constructor(props) {
        super(props);
        this.state = {
            showInBetween:false,
        }
    }


    render(){
        return(
            <React.Fragment>
                <div className="modal fade form_modal" id={SampleTestReportModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">Sample Report</h5>
                                <button style={{outline: 'none'}}  type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {this.props.handleShowInBetween()}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body my-0 mx-3">
                                <div className={"container border"}>
                                    <div className={"row"}>
                                        <div className={"col-12  pt-2"}>
                                            {this.props.selectedTest.ReportDescription ? Parser(this.props.selectedTest.ReportDescription) : ""}
                                        </div>
                                        <div className="col-6  pt-2 text-center border border-info responsive-p verlag-bold">
                                            <h4>My Facility</h4>
                                            <p>1234 W. Fake St, Fake City, OK 74075</p>
                                            <p>E-Mail:</p>
                                            <p>help@mydomain.com</p>
                                        </div>
                                        <div className="col-6  pt-2 text-center border border-info responsive-p verlag-bold">
                                            <h4>CLIA: 123456</h4>
                                            <br/>
                                            <p>Laboratory Director</p><p>
                                            Dr. Little Stockings</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">

                                <button type="button" className="btn btn-primary" onClick={() => {hideModal(SampleTestReportModal.ID); this.props.handleShowInBetween()}}>
                                    Close
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}